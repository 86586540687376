// -----------------
// PAGE - IMPRESSUM
// -----------------
section.impressum {
  img {
    margin: unset;
    width: 100%;
  }

  .im-title {
    font-size: $p-title-font-size-sm;
    font-weight: bold;
    margin: $geo_size_sm 0;

    @include md {
      margin: $geo_size_nm 0;
      font-size: $p-title-font-size-md;
    }
    @include lg {
      margin: $geo_size_md 0;
      font-size: $p-title-font-size-lg;
    }
    @include xl {
      margin: $geo_size_lg 0;
      font-size: $p-title-font-size-xl;
    }
  }

  .im-content {
    font-size: $txt-font-size-sm;

    @include md {
      font-size: $txt-font-size-md;
    }
    @include lg {
      font-size: $txt-font-size-lg;
    }
    @include xl {
      font-size: $txt-font-size-xl;
    }

    .hb {
      margin-bottom: $geo_size_nm;

      @include md {
        margin-bottom: $geo_size_md;
      }
      @include lg {
        margin-bottom: $geo_size_lg;
      }
      @include xl {
        margin-bottom: $geo_size_xl;
      }

      div {
        margin-bottom: $geo_size_sm;

        @include md {
          margin-bottom: $geo_size_nm;
        }
        @include lg {
          margin-bottom: $geo_size_md;
        }
        @include xl {
          margin-bottom: $geo_size_lg;
        }
      }
    }
  }
}