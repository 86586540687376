// -- Layout

$layout_headbar_height: 60px;
$layout_sidebar_width: 255px;
$header_font_size: 16px;

// -- geo sizes

$geo_size_xs: 6px;
$geo_size_sm: 12px;
$geo_size_nm: 18px;
$geo_size_md: 24px;
$geo_size_lg: 30px;
$geo_size_xl: 36px;


// -- font sizes

$p-title-font-size-sm: 20px;
$title-font-size-sm: 14px;
$txt-font-size-sm: 12px;

$p-title-font-size-md: 24px;
$title-font-size-md: 14px;
$txt-font-size-md: 12px;

$p-title-font-size-lg: 28px;
$title-font-size-lg: 18px;
$txt-font-size-lg: 16px;

$p-title-font-size-xl: 30px;
$title-font-size-xl: 22px;
$txt-font-size-xl: 20px;


// -- Styles

body {
  margin: 0;
  padding: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $pageBackground;
  
  @include md {
    width: 750px;
    margin-right: auto;
    margin-left: auto;
  }

  @include lg {
    width: 970px;
  }

  @include xl {
    width: 1170px;
  }

  & #root {
    min-height: 100vh;
    background-color: $appBackground;
  }
}

a, a:visited, a:focus, a:active {
  text-decoration: none;
  color: $primaryColor;//$font_dark;

  &:hover {
    color: darken($primaryColor, 5%);//$primaryColor;
  }
}


h1, h2, h3, h4, h5, h6 {
  margin: 0;
  color: $font_dark;
}


// --- Standart nav
.site-nav {
  ul {
    margin: 0;
    padding: 0;
    *zoom: 1;

    &:before, &:after {
      content: "";
      display: table;
    }

    &:after {
      clear: both;
    }

    li {
      display: flex;
      list-style: none;
      float: left;

      &:not(:last-child):after {
        content: '\2022';
        align-self: center;
        margin: {
          left: $geo_size_xs;
          right: $geo_size_xs;
        }
      }

      a {
        color: $font_dark;

        &:link, &:visited {
          display: block;
          text-decoration: none;
        }
        &:hover {
          color: $primaryColor;
        }
      }
    }
  }
}

.content {
  margin-left: 5%;
  @include md {
    margin-left: 21%;
  }
}
.btn-content {
  margin-left: 3%;
  @include md {
    margin-left: 18%;
  }
}