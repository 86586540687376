// -----------------
// PAGE - DATENSCHUTZ
// -----------------
section.datenschutz {
  .ds-title {
    font-size: $p-title-font-size-sm;
    font-weight: bold;
    margin-bottom: $geo_size_sm;

    @include md {
      margin-bottom: $geo_size_nm;
      font-size: $p-title-font-size-md;
    }
    @include lg {
      margin-bottom: $geo_size_md;
      font-size: $p-title-font-size-lg;
    }
    @include xl {
      margin-bottom: $geo_size_lg;
      font-size: $p-title-font-size-xl;
    }
  }

  .ds-content {
    font-size: $txt-font-size-sm;

    @include md {
      font-size: $txt-font-size-md;
    }
    @include lg {
      font-size: $txt-font-size-lg;
    }
    @include xl {
      font-size: $txt-font-size-xl;
    }

    .hb {
      margin-bottom: $geo_size_nm;
  
      @include md {
        margin-bottom: $geo_size_md;
      }
      @include lg {
        margin-bottom: $geo_size_lg;
      }
      @include xl {
        margin-bottom: $geo_size_xl;
      }

      .hb-title {
        color: $secondaryColor;
        font-weight: bold;
        font-size: $title-font-size-sm;
        margin-bottom: $geo_size_sm;
  
        @include md {
          font-size: $title-font-size-md;
          margin-bottom: $geo_size_nm;
        }
        @include lg {
          font-size: $title-font-size-lg;
          margin-bottom: $geo_size_md;
        }
        @include xl {
          font-size: $title-font-size-xl;
          margin-bottom: $geo_size_lg;
        }
      }
  
      .hb-content {
        .sb {
          margin-bottom: $geo_size_sm;
  
          @include md {
            margin-bottom: $geo_size_nm;
          }

          .sb-title {
            color: $secondaryColor;
            font-weight: bold;
          }


          .sb-content div{
            margin-bottom: $geo_size_sm;
          }
        }
      }
    }
  }
}