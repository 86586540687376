header {

  & .header-container {
    padding: 20px 0;

    @include lg {
      display: flex;
      padding: 40px 0;
    }

    @include xl {
      padding: 40px;
    }


    & img{
      height: 45px;

      @include md {
        height: 85px;
      }
    }

    & .mobile-nav-toggle {
      display: contents;

      @include lg {
        display: none;
      }
    }

    & .disabled-site-nav{
      display: none;
    }

    & .mobile-site-nav{
      & ul {
        margin: 0;
        padding: 0;

        & li {
          display: flex;
          align-items: center;  
          list-style: none;
          height: 30px;

          & a {
            display: block;
            color: #fff !important;
            background-color: $brown;
            width: 100%;
            height: 96%;

            &.active {
              background-color: $green;
            }
          }
        }
      }
    }

    & .elements {
      width: 100%;

      & .actions {
        display: none;

        @include lg {
          display: flex;
          justify-content: flex-end;
          margin-top: -8%;
        }

        @include xl {
          margin-top: -7%;
        }

        & img {
          height: 40px;
          margin-right: $geo_size_md;

          @include lg {
            height: 50px;
          }
        }
      }

      & nav {
        @include lg {
          margin-left: 8.5%;
          margin-bottom: 8px;
          margin-top: auto;
          margin-top: 9%;
        }
        @include xl {
          margin-top: 8%;
        }

        & ul {
          & li {
            & a {
              position: relative;
              color: $brown;
              letter-spacing: 1px;
              zoom: 1;
              font: {
                size: $header_font_size;
                weight: 600;
              }
  
              &:hover {
                color: $green;
              }
  
              &.active {
                color: $green;
              }
            }
          }
        }
      }
    }
  }
}


// -- mobile hamburger

#nav-ham {
  width: 40px;
  height: 29px;
  position: relative;
  margin: auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

#nav-ham span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: $brown;
  border-radius: 4px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-ham span:nth-child(1) {
  top: 2px;
}

#nav-ham span:nth-child(2) {
  top: 11px;
}

#nav-ham span:nth-child(3) {
  top: 20px;
}

#nav-ham.open span:nth-child(1) {
  top: 12px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-ham.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#nav-ham.open span:nth-child(3) {
  top: 12px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}