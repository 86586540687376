$title-font-size-sm: 14px;
$txt-font-size-sm: 12px;

$title-font-size-md: 14px;
$txt-font-size-md: 12px;

$title-font-size-lg: 18px;
$txt-font-size-lg: 16px;

$title-font-size-xl: 22px;
$txt-font-size-xl: 20px;


// -----------------
// PAGE - HOME
// -----------------
section.home {
  & .hb {
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain; /* Resize the background image to cover the entire container */
    @include md {
      background-size: cover; /* Resize the background image to cover the entire container */
    }

    &.b1 {
      background-image: url('../assets/b01-langverpackung.jpg');
    }
    &.b1 .content {
      padding-top: 48%;
      margin-top: -10%;
      margin-bottom: 10px;

      font-size: $txt-font-size-sm;

      @include md {
        margin-top: unset;
        margin-bottom: unset;

        font-size: $txt-font-size-md;
        padding-top: 39%;
        padding-bottom: 2%;
      }
      @include lg {
        font-size: $txt-font-size-lg;
      }
      @include xl {
        font-size: $txt-font-size-xl;
      }
      

      & .title {
        font: {
          size: $title-font-size-sm;
          weight: bold;
        }
        line-break: auto;
        padding-bottom: 0;

        @include md {
          font-size: $title-font-size-md;
          padding-bottom: $geo_size_xs;
        }
        @include lg {
          font-size: $title-font-size-lg;
          padding-bottom: $geo_size_sm;
        }
        @include xl {
          font-size: $title-font-size-xl;
          padding-bottom: $geo_size_nm;
        }
      }
    }

    &.b2 {
      padding-bottom: 3%;
    }

    &.b3 {
      background-image: url('../assets/b02-lieferprogramm.jpg');
    }

    &.b3 .btn-content {
      padding-top: 29%;
    }

    &.b4 {
      color: $green;
      background-color: #E2F2E3;
      height: fit-content;
      padding-bottom: 0;
      font: {
        size: $title-font-size-sm;
        weight: bold;
      }
      @include md {
        font-size: $title-font-size-md;
        padding-bottom: $geo_size_xs;
      }
      @include lg {
        font-size: $title-font-size-lg;
        padding-bottom: $geo_size_sm;
      }
      @include xl {
        font-size: $title-font-size-xl;
        padding-bottom: $geo_size_nm;
      }

      & .content {
        padding: 2% 0;
      }
    }

    &.b5 {
      color: $brown;
      height: fit-content;
      padding-bottom: 0;
      font: {
        size: $title-font-size-sm;
        weight: bold;
      }
      @include md {
        font-size: $title-font-size-md;
        padding-bottom: $geo_size_xs;
      }
      @include lg {
        font-size: $title-font-size-lg;
        padding-bottom: $geo_size_sm;
      }
      @include xl {
        font-size: $title-font-size-xl;
        padding-bottom: $geo_size_nm;
      }

      & .content {
        padding: 2% 0;

        & .space {
          margin-bottom: 10px;
        }
      }
      & .btn-content {
        padding: 2% 0;
      }
    }

    &.b6 {
      background-image: url('../assets/b03-wald.jpg');
    }

    &.b6 .content {
      padding-top: 109%;
      margin-top: -54%;
      margin-bottom: 10px;

      font-size: $txt-font-size-sm;

      @include md {
        margin-top: unset;
        margin-bottom: unset;
        font-size: $txt-font-size-md;
        padding-top: 11%;
        padding-bottom: 19%;
      }
      @include lg {
        font-size: $txt-font-size-lg;
      }
      @include xl {
        font-size: $txt-font-size-xl;
      }

      & .title {
        font: {
          size: $title-font-size-sm;
          weight: bold;
        }
        line-break: auto;
        padding-bottom: 0;

        @include md {
          font-size: $title-font-size-md;
          padding-bottom: $geo_size_xs;
        }
        @include lg {
          font-size: $title-font-size-lg;
          padding-bottom: $geo_size_sm;
        }
        @include xl {
          font-size: $title-font-size-xl;
          padding-bottom: $geo_size_nm;
        }
      }
    }

    &.b7 {
      padding-bottom: 0;
      font-size: $txt-font-size-sm;

      @include md {
        font-size: $txt-font-size-md;
        padding-bottom: $geo_size_xs;
      }
      @include lg {
        font-size: $txt-font-size-lg;
        padding-bottom: $geo_size_sm;
      }
      @include xl {
        font-size: $txt-font-size-xl;
        padding-bottom: $geo_size_nm;
      }

      & .content {
        padding: 2% 0;
  
        & .title {
          font: {
            size: $title-font-size-sm;
            weight: bold;
          }
          padding-bottom: 0;
  
          @include md {
            font-size: $title-font-size-md;
            padding-bottom: $geo_size_xs;
          }
          @include lg {
            font-size: $title-font-size-lg;
            padding-bottom: $geo_size_sm;
          }
          @include xl {
            font-size: $title-font-size-xl;
            padding-bottom: $geo_size_nm;
          }
        }
      }
    }

    &.b8 {
      background-image: url('../assets/b04-hilozarbeiter-sw.jpg');
    }

    &.b8 .content {
      padding-top: 40%;

      font-size: $txt-font-size-sm;

      @include md {
        padding-top: 33%;
        padding-bottom: 3%;
        font-size: $txt-font-size-md;
      }
      @include lg {
        font-size: $txt-font-size-lg;
      }
      @include xl {
        font-size: $txt-font-size-xl;
      }

      & .title {
        font: {
          size: $title-font-size-sm;
          weight: bold;
        }
        padding-bottom: 0;

        @include md {
          font-size: $title-font-size-md;
          padding-bottom: $geo_size_xs;
        }
        @include lg {
          font-size: $title-font-size-lg;
          padding-bottom: $geo_size_sm;
        }
        @include xl {
          font-size: $title-font-size-xl;
          padding-bottom: $geo_size_nm;
        }
      }
    }
  }
}