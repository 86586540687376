// -----------------
// BUTTONS
// -----------------
div {
  & .btn {
    padding: 5px 10px;
    font-size: 14px;
    color: $brown;
    height: 20px;
    font-weight: bold;
    width: fit-content;

    @include md {
      padding: 10px 23px;
      height: 20px;
      font-size: 18px;
    }

    @include lg {
      padding: 10px 30px;
      height: 30px;
      font-size: 20px;
    }

    @include xl {
      padding: 10px 35px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  & .btn-white{
    background-color: $background-white;
    
    &:hover {
      background-color: darken($background-white, 10%)
    }
  }
  & .btn-green{
    background-color: $green;
    color: white;

    &:hover {
      background-color: darken($green, 10%)
    }
  }
  & .btn-brown{
    //background-color: rgba(102, 51, 0, 0.4);
    background-color: $brown;
    color: white;
    &:hover {
      background-color: lighten($brown, 5%)
    }
  }
  & .btn-light-brown{
    background-color: $light-brown;
    color: $brown;
    &:hover {
      background-color: darken($light-brown, 5%)
    }
  }
}