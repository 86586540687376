// -----------------
// PAGE - IMPRESSUM
// -----------------
section.kontakt {
  .content {
    font-size: $txt-font-size-sm;
    display: flex;
    flex-direction: column-reverse;

    @include md {
      flex-direction: unset;
      font-size: $txt-font-size-md;
    }
    @include lg {
      font-size: $txt-font-size-lg;
    }
    @include xl {
      font-size: $txt-font-size-xl;
    }

    img {
      width: 95%;
      @include md {
        width: 60%;
      }
    }

    .infos {
      width: 40%;
      .title {
        font-size: $p-title-font-size-sm;
        font-weight: bold;
        margin: $geo_size_sm 0;
    
        @include md {
          margin: $geo_size_nm 0;
          font-size: $p-title-font-size-md;
        }
        @include lg {
          margin: $geo_size_md 0;
          font-size: $p-title-font-size-lg;
        }
        @include xl {
          margin: $geo_size_lg 0;
          font-size: $p-title-font-size-xl;
        }
      }

      .contact {
        div {
          margin-bottom: $geo_size_sm;
        }
      }
    }

    
  }
}