footer {
  font-size: 84%;
  color: $brown;

  & .footer-container {

    & .f-fr {

      @include md {
        display: flex;
        justify-content: space-between;
        height: 130px;
        padding: 0 30px;
      }

      @include lg {
        height: 170px;
      }

      & .logos {
        display: flex;
        justify-content: space-around;
        padding: 5px;

        @include md {
          padding: unset;
          margin-top: auto;
          width: 50%;
          justify-content: unset;
        }

        & img {
          height: 45px;

          @include md {
            height: 50px;
            margin-right: $geo_size_md;
          }
          @include lg {
            height: 60px;
          }
        }
      }

      & .actions {
        display: flex;
        justify-content: space-around;
        padding: 5px;

        @include md {
          padding: unset;
          width: 50%;
          justify-content: flex-end;
        }

        & img {
          height: 40px;

          @include md {
            height: 40px;
            margin-right: $geo_size_md;
          }

          @include lg {
            height: 50px;
          }
        }
      }
    }

    & .f-sr {
      text-align: center;
      background-color: #EEE6DA;

      @include lg {
        display: flex;
        align-items: center;
        height: 100px;
        padding: 0 30px;
      }

      & nav {
        color: $grey500;
        display: flex;
        justify-content: center;
  
        @include lg {
          margin: {
            left: $geo_size_sm;
            right: $geo_size_sm;
          }
        }
      }

      & .footer-box {
        @include lg {
          display: flex;
        }

        & .conctact {
          @include lg {
            margin: {
              left: $geo_size_sm;
              right: $geo_size_sm;
            }
          }
        }
      }
    }
  } 
}
