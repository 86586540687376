// -----------------
// PAGE - STANDORTE
// -----------------
section.standorte {

  .container {
    color: $secondaryColor;
    @include md {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
    
  }

  .content {

    .title {
      font: {
        size: $title-font-size-sm;
        weight: bold;
      }
      line-break: auto;
      padding-bottom: 0;
  
      @include md {
        font-size: $title-font-size-md;
        padding-bottom: $geo_size_xs;
      }
      @include lg {
        font-size: $title-font-size-lg;
        padding-bottom: $geo_size_sm;
      }
      @include xl {
        font-size: $title-font-size-xl;
        padding-bottom: $geo_size_nm;
      }
    }

    img {
      width: 60%
    }

    .info {
      font-size: $txt-font-size-sm;

      @include md {
        width: 50%;
        font-size: $txt-font-size-md;
      }
      @include lg {
        font-size: $txt-font-size-lg;
      }
      @include xl {
        font-size: $txt-font-size-xl;
      }

      div {
        margin-bottom: $geo_size_sm;
      }
    }    
  }
}