// Typography utils
.txt-b {font-weight: bold;}
.txt-n {font-weight: normal;}
.txt-i {font-style: italic;}
.txt-u {text-decoration: underline;}
.txt-font-mono {font-family: monospace;}

.txt-c-n {color: $font_dark;}
.txt-c-dim {color: $font_dark_light;}
.txt-c-pr {color: $primaryColor;}
.txt-c-se {color: $secondaryColor;}
.txt-c-success {color: $c_success;}
.txt-c-info {color: $c_info;}
.txt-c-danger {color: $c_danger;}
.txt-c-warn {color: $c_warn;}

.txt-xs {font-size: 12px;}
.txt-sm {font-size: 14px;}
.txt-nm {font-size: 16px;}
.txt-md {font-size: 18px;}
.txt-lg {font-size: 20px;}
.txt-xl {font-size: 22px;}

.txt-ta-l {text-align: left;}
.txt-ta-c {text-align: center;}
.txt-ta-r {text-align: right;}
.txt-ta-j {text-align: justify;}

.txt-uc {text-transform: uppercase;}
.txt-lc {text-transform: lowercase;}
.txt-cp {text-transform: capitalize;}
.txt-n {text-transform: none;}

// Flex/layout utils
.d-f {display: flex;}
.d-b {display: block;}
.d-i {display: inline;}
.d-ib {display: inline-block;}
.d-if {display: inline-flex;}

.f-w {flex-wrap: wrap;}
.f-wr {flex-wrap: wrap-reverse;}
.f-nw {flex-wrap: nowrap;}

.f-d-c {flex-direction: column;}
.f-d-cr {flex-direction: column-reverse;}
.f-d-r {flex-direction: row;}
.f-d-rr {flex-direction: row-reverse;}

// Analogous to h1-h6 levels
.f-1 {flex: 1}
.f-2 {flex: 2}
.f-3 {flex: 3}
.f-4 {flex: 4}
.f-5 {flex: 5}
.f-6 {flex: 6}

.f-ai-c {align-items: center;}
.f-ai-b {align-items: baseline;}
.f-ai-fs {align-items: flex-start;}
.f-ai-fe {align-items: flex-end;}

.f-ac-c {align-content: center;}
.f-ac-fs {align-content: flex-start;}
.f-ac-fe {align-content: flex-end;}

.f-ji-c {justify-items: center;}
.f-ji-fs {justify-items: flex-start;}
.f-ji-fe {justify-items: flex-end;}

.f-jc-c {justify-content: center;}
.f-jc-fs {justify-content: flex-start;}
.f-jc-fe {justify-content: flex-end;}
.f-jc-sb {justify-content: space-between;}
.f-jc-sa {justify-content: space-around;}

.autospace, .flex-grid {
  & > *:not(:first-child) {
    margin-left: 20px;
  }
}


// Geometry utils (xs-xl analogous to h1-h6 levels

// Geom: paddings

.pd-0 {padding: 0;}
.pd-xs {padding: $geo_size_xs;}
.pd-sm {padding: $geo_size_sm;}
.pd-nm {padding: $geo_size_nm;}
.pd-md {padding: $geo_size_md;}
.pd-lg {padding: $geo_size_lg;}
.pd-xl {padding: $geo_size_xl;}

.pd-h-0 {padding-left: 0; padding-right: 0;}
.pd-h-xs {padding-left: $geo_size_xs; padding-right: $geo_size_xs;}
.pd-h-sm {padding-left: $geo_size_sm; padding-right: $geo_size_sm;}
.pd-h-nm {padding-left: $geo_size_nm; padding-right: $geo_size_nm;}
.pd-h-md {padding-left: $geo_size_md; padding-right: $geo_size_md;}
.pd-h-lg {padding-left: $geo_size_lg; padding-right: $geo_size_lg;}
.pd-h-xl {padding-left: $geo_size_xl; padding-right: $geo_size_xl;}

.pd-v-0 {padding-top: 0; padding-bottom: 0;}
.pd-v-xs {padding-top: $geo_size_xs; padding-bottom: $geo_size_xs;}
.pd-v-sm {padding-top: $geo_size_sm; padding-bottom: $geo_size_sm;}
.pd-v-nm {padding-top: $geo_size_nm; padding-bottom: $geo_size_nm;}
.pd-v-md {padding-top: $geo_size_md; padding-bottom: $geo_size_md;}
.pd-v-lg {padding-top: $geo_size_lg; padding-bottom: $geo_size_lg;}
.pd-v-xl {padding-top: $geo_size_xl; padding-bottom: $geo_size_xl;}

.pd-t-0 {padding-top: 0;}
.pd-t-xs {padding-top: $geo_size_xs;}
.pd-t-sm {padding-top: $geo_size_sm;}
.pd-t-nm {padding-top: $geo_size_nm;}
.pd-t-md {padding-top: $geo_size_md;}
.pd-t-lg {padding-top: $geo_size_lg;}
.pd-t-xl {padding-top: $geo_size_xl;}

.pd-r-0 {padding-right: 0;}
.pd-r-xs {padding-right: $geo_size_xs;}
.pd-r-sm {padding-right: $geo_size_sm;}
.pd-r-nm {padding-right: $geo_size_nm;}
.pd-r-md {padding-right: $geo_size_md;}
.pd-r-lg {padding-right: $geo_size_lg;}
.pd-r-xl {padding-right: $geo_size_xl;}

.pd-b-0 {padding-bottom: 0;}
.pd-b-xs {padding-bottom: $geo_size_xs;}
.pd-b-sm {padding-bottom: $geo_size_sm;}
.pd-b-nm {padding-bottom: $geo_size_nm;}
.pd-b-md {padding-bottom: $geo_size_md;}
.pd-b-lg {padding-bottom: $geo_size_lg;}
.pd-b-xl {padding-bottom: $geo_size_xl;}

.pd-l-0 {padding-left: 0;}
.pd-l-xs {padding-left: $geo_size_xs;}
.pd-l-sm {padding-left: $geo_size_sm;}
.pd-l-nm {padding-left: $geo_size_nm;}
.pd-l-md {padding-left: $geo_size_md;}
.pd-l-lg {padding-left: $geo_size_lg;}
.pd-l-xl {padding-left: $geo_size_xl;}

// Geom: margins

.mg-0 {margin: 0;}
.mg-a {margin: auto;}
.mg-xs {margin: $geo_size_xs;}
.mg-sm {margin: $geo_size_sm;}
.mg-nm {margin: $geo_size_nm;}
.mg-md {margin: $geo_size_md;}
.mg-lg {margin: $geo_size_lg;}
.mg-xl {margin: $geo_size_xl;}

.mg-h-0 {margin-left: 0; margin-right: 0;}
.mg-h-a {margin-left: auto; margin-right: auto;}
.mg-h-xs {margin-left: $geo_size_xs; margin-right: $geo_size_xs;}
.mg-h-sm {margin-left: $geo_size_sm; margin-right: $geo_size_sm;}
.mg-h-nm {margin-left: $geo_size_nm; margin-right: $geo_size_nm;}
.mg-h-md {margin-left: $geo_size_md; margin-right: $geo_size_md;}
.mg-h-lg {margin-left: $geo_size_lg; margin-right: $geo_size_lg;}
.mg-h-xl {margin-left: $geo_size_xl; margin-right: $geo_size_xl;}

.mg-v-0 {margin-top: 0; margin-bottom: 0;}
.mg-v-a {margin-top: auto; margin-bottom: auto;}
.mg-v-xs {margin-top: $geo_size_xs; margin-bottom: $geo_size_xs;}
.mg-v-sm {margin-top: $geo_size_sm; margin-bottom: $geo_size_sm;}
.mg-v-nm {margin-top: $geo_size_nm; margin-bottom: $geo_size_nm;}
.mg-v-md {margin-top: $geo_size_md; margin-bottom: $geo_size_md;}
.mg-v-lg {margin-top: $geo_size_lg; margin-bottom: $geo_size_lg;}
.mg-v-xl {margin-top: $geo_size_xl; margin-bottom: $geo_size_xl;}

.mg-t-0 {margin-top: 0;}
.mg-t-a {margin-top: auto;}
.mg-t-xs {margin-top: $geo_size_xs;}
.mg-t-sm {margin-top: $geo_size_sm;}
.mg-t-nm {margin-top: $geo_size_nm;}
.mg-t-md {margin-top: $geo_size_md;}
.mg-t-lg {margin-top: $geo_size_lg;}
.mg-t-xl {margin-top: $geo_size_xl;}

.mg-r-0 {margin-right: 0;}
.mg-r-a {margin-right: auto;}
.mg-r-xs {margin-right: $geo_size_xs;}
.mg-r-sm {margin-right: $geo_size_sm;}
.mg-r-nm {margin-right: $geo_size_nm;}
.mg-r-md {margin-right: $geo_size_md;}
.mg-r-lg {margin-right: $geo_size_lg;}
.mg-r-xl {margin-right: $geo_size_xl;}

.mg-b-0 {margin-bottom: 0;}
.mg-b-a {margin-bottom: auto;}
.mg-b-xs {margin-bottom: $geo_size_xs;}
.mg-b-sm {margin-bottom: $geo_size_sm;}
.mg-b-nm {margin-bottom: $geo_size_nm;}
.mg-b-md {margin-bottom: $geo_size_md;}
.mg-b-lg {margin-bottom: $geo_size_lg;}
.mg-b-xl {margin-bottom: $geo_size_xl;}

.mg-l-0 {margin-left: 0;}
.mg-l-a {margin-left: auto;}
.mg-l-xs {margin-left: $geo_size_xs;}
.mg-l-sm {margin-left: $geo_size_sm;}
.mg-l-nm {margin-left: $geo_size_nm;}
.mg-l-md {margin-left: $geo_size_md;}
.mg-l-lg {margin-left: $geo_size_lg;}
.mg-l-xl {margin-left: $geo_size_xl;}

.cursor-pointer {
  cursor: pointer;
}
