$primaryColor: #009900;
$secondaryColor: #663300;

$appBackground: #fff;//#f8f8ff;
$pageBackground: #f8f8ff;

// greys
$grey900: #212121;
$grey800: #424242;
$grey700: #616161;
$grey600: #757575;
$grey500: #9e9e9e;
$grey400: #bdbdbd;
$grey300: #e0e0e0;
$grey200: #eeeeee;
$grey100: #f5f5f5;
$grey50: #fafafa;

// fonts
$font_dark: $grey900;
$font_dark_light: $grey600;

$font_light: $grey200;
$font_light_dark: $grey500;

// messages
$c_success: #5cb85c;
$c_info: #5bc0de;
$c_warn: #f0ad4e;
$c_danger: #d9534f;


// -----------------
// HARPERS-COLORS
// -----------------
$brigth-green: #ccffcc;
$green: #009900;
$black: #000000;
$background-white: #ffffff;
$grey: #32373c;
$bright-grey: #82878c;
$brown: #663300;
$light-brown: #EEE6DA;
